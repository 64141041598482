import Program from "./shader";

const nPoints = 100;

export default class Render {
	private readonly shaderProgram: Program;
	private readonly VAO: WebGLVertexArrayObject;
	private readonly points: { x: number, y: number, dx: number, dy: number }[];
	constructor(readonly gl: WebGL2RenderingContext) {
		//Shaders
		this.shaderProgram = new Program(gl, "render.vsh", "render.fsh");

		//Buffers
		const VAO = gl.createVertexArray();
		if (!VAO) throw new Error("Could not create Vertex Array.")
		this.VAO = VAO;
		let VBO = gl.createBuffer();

		gl.bindVertexArray(this.VAO);

		gl.bindBuffer(gl.ARRAY_BUFFER, VBO);
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, 1, -1, 1, 1, -1, 1]), gl.STATIC_DRAW);

		gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 8, 0);
		gl.enableVertexAttribArray(0);

		gl.bindBuffer(gl.ARRAY_BUFFER, null);
		gl.bindVertexArray(null);

		//Gen all points
		this.points = Array.from({ length: nPoints }, value => ({
			x: Math.random(),
			y: Math.random(),
			dx: Math.random() * 2 - 1,
			dy: Math.random() * 2 - 1
		}));
	}

	render = (time: number, width: number, height: number) => {
		//Process point movement
		this.points.forEach((value, i) => {
			value.x += value.dx / width;
			value.y += value.dy / height;
			//Old movement
			// if (i < 10) {
			// 	value.y -= 1 / height;
			// } else if (i < 20) {
			// 	value.x -= 1 / width;
			// } else {
			// 	value.x += 1 / width;
			// }
			value.x = mod(value.x, 1);
			value.y = mod(value.y, 1);
		});

		//Render
		this.shaderProgram.use();

		this.shaderProgram.uniformi(1, "nPoints", this.points.length);
		for (let i = 0; i < this.points.length; i++) {
			this.shaderProgram.uniform(2, "points[" + i + "]",
				this.points[i].x * width,
				this.points[i].y * height);
		}

		this.shaderProgram.uniform(2, "size", width, height);

		this.gl.bindVertexArray(this.VAO);
		this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, 4);
	}
}

function mod(n: number, p: number) {
	return n - p * Math.floor(n / p);
}
