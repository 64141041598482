class Program {
	private readonly ID: WebGLProgram;
	constructor(readonly gl: WebGL2RenderingContext, ...shaderNames: string[]) {
		const ID = gl.createProgram();
		if (!ID) throw new Error("Could not create program");
		this.ID = ID;

		let shaders = new Array<WebGLShader>(shaderNames.length);
		for (let i = 0; i < shaderNames.length; i++) {
			shaders[i] = getShader(this.gl, shaderNames[i]);
			gl.attachShader(this.ID, shaders[i]);
		}
		gl.linkProgram(this.ID);
		for (let i = 0; i < shaders.length; i++) {
			gl.detachShader(this.ID, shaders[i]);
			gl.deleteShader(shaders[i]);
		}

		if (!gl.getProgramParameter(this.ID, gl.LINK_STATUS))
			throw 'An error occoured linking the shaders "' + shaderNames.join('", "') + '": ' + gl.getProgramInfoLog(this.ID);

	}

	use() { this.gl.useProgram(this.ID) };

	private get(name: string) {
		return this.gl.getUniformLocation(this.ID, name);
	}

	//Not all uniforms are implemented yet, more will be added as need be.
	uniform(size: 1, name: string, d1: number): void;
	uniform(size: 2, name: string, d1: number, d2: number): void;
	uniform(size: 3, name: string, d1: number, d2: number, d3: number): void;
	uniform(size: 4, name: string, d1: number, d2: number, d3: number, d4: number): void;
	uniform(size: 1 | 2 | 3 | 4, name: string, data: Float32Array | number[]): void;
	uniform(size: 1 | 2 | 3 | 4, name: string, data: Float32Array | number[] | number, ...d: number[]) {
		let location = this.get(name);

		if (typeof data == "number") {
			data = [data];
			if (d) {
				data = data.concat(d);
			}
		}

		if (data instanceof Array)
			data = new Float32Array(data);

		switch (size) {
			case 1:
				this.gl.uniform1fv(location, data);
				break;
			case 2:
				this.gl.uniform2fv(location, data);
				break;
			case 3:
				this.gl.uniform3fv(location, data);
				break;
			case 4:
				this.gl.uniform4fv(location, data);
				break;
		}
	}

	uniformi(size: 1, name: string, d1: number): void;
	uniformi(size: 2, name: string, d1: number, d2: number): void;
	uniformi(size: 3, name: string, d1: number, d2: number, d3: number): void;
	uniformi(size: 4, name: string, d1: number, d2: number, d3: number, d4: number): void;
	uniformi(size: 1 | 2 | 3 | 4, name: string, data: Int32Array | number[]): void;
	uniformi(size: 1 | 2 | 3 | 4, name: string, data: Int32Array | number[] | number, ...d: number[]) {
		let location = this.get(name);

		if (typeof data == "number") {
			data = [data];
			if (d) {
				data = data.concat(d);
			}
		}

		if (data instanceof Array)
			data = new Int32Array(data);

		switch (size) {
			case 1:
				this.gl.uniform1iv(location, data);
				break;
			case 2:
				this.gl.uniform2iv(location, data);
				break;
			case 3:
				this.gl.uniform3iv(location, data);
				break;
			case 4:
				this.gl.uniform4iv(location, data);
				break;
		}
	}

	uniformMatrix(name: string, data: Float32Array | number[]) {
		let location = this.get(name);

		if (data instanceof Array)
			data = new Float32Array(data);

		switch (data.length) {
			case 4:
				this.gl.uniformMatrix2fv(location, false, data);
				break;
			case 8:
				this.gl.uniformMatrix3fv(location, false, data);
				break;
			case 16:
				this.gl.uniformMatrix4fv(location, false, data);
				break;
			default:
				throw "Could not determine matrix size";
		}
	}
}

function getShader(gl: WebGL2RenderingContext, name: string) {
	let type: number;
	if (name.match(/.vsh$/)) {
		type = gl.VERTEX_SHADER;
	} else if (name.match(/.fsh$/)) {
		type = gl.FRAGMENT_SHADER;
	} else {
		throw "Could not determine shader type.";
	}

	let source = require("!raw-loader!./shaders/" + name + ".glsl");

	let shader = gl.createShader(type);
	if (!shader) throw new Error("Could not create shader.");

	gl.shaderSource(shader, source);

	gl.compileShader(shader);

	if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
		let log = gl.getShaderInfoLog(shader);
		gl.deleteShader(shader);
		throw 'An error occurred compiling the shader "' + name + '": ' + log;
	}

	return shader;
}

export default Program;
