import * as React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import style from "./pageTransition.module.scss";

const PageTransition = ({ children, location }: { children: any, location: { pathname: string } }) => (
	<TransitionGroup component={null}>
		<CSSTransition key={location.pathname.split('/')[1] || ""} timeout={parseInt(style.transitionLength)} classNames={style}>
			<div className={style.item}>
				{children}
			</div>
		</CSSTransition>
	</TransitionGroup>
);

export default PageTransition;
