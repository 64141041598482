// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-markdown-tsx": () => import("/opt/build/repo/src/templates/markdown.tsx" /* webpackChunkName: "component---src-templates-markdown-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("/opt/build/repo/src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-posts-tsx": () => import("/opt/build/repo/src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */)
}

