import { CanvasRenderer } from "..";
import Render from "./render";

export default class Voronoi implements CanvasRenderer {
	gl?: WebGL2RenderingContext;
	render?: Render;

	activate(canvas: HTMLCanvasElement): void {
		this.gl = canvas.getContext("webgl2") || undefined;

		if (this.gl) {
			this.render = new Render(this.gl);
			this.draw();
		}
	}
	draw = () => {
		if (this.gl && this.render) {
			const gl = this.gl;
			//Resize if need-be
			let width = gl.canvas.clientWidth * (devicePixelRatio || 1);
			let height = gl.canvas.clientHeight * (devicePixelRatio || 1);
			const aspect = width / height;
			if (gl.canvas.width != width || gl.canvas.height != height) {

				gl.canvas.width = width;
				gl.canvas.height = height;

				gl.viewport(0, 0, width, height);
			}

			this.render.render(0, width, height);
			window.requestAnimationFrame(this.draw);
		}
	}
	deactivate(): void {
		this.gl = undefined;
	}
}
