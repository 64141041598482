import * as React from "react";
import { useStaticQuery, graphql, Link as GLink } from "gatsby";

import Background from "./background";
import NavBar from "./navbar";

import style from "./header.module.scss";

const Link = (props: any) => <GLink activeClassName={style.active} partiallyActive={true} {...props} />

const Header = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						subtitle
					}
				}
			}
		`
	);
	return <header className={style.header}>
		<Background />
		<span className={style.title}>{data.site.siteMetadata.title}</span>
		<span className={style.subtitle}>{data.site.siteMetadata.subtitle}</span>
		<NavBar>
			<Link partiallyActive={false} to="/">Home</Link>
			<Link to="/portfolio/">Portfolio</Link>
		</NavBar>
	</header >
}

export default Header;
