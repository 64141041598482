import * as React from "react";
import "./layout.css";

import Header from "./header";
import PageTransition from "./pageTransition";

const Layout = ({ children, location }: { children: any, location: { pathname: string } }) => {
	let content = children;
	console.log(location.pathname.split('/')[1]);
	switch (location.pathname.split('/')[1]) {
		//When site becomes more fluid move pages here
	}
	return <>
		<Header />
		<main>
			<PageTransition location={location}>
				{content}
			</PageTransition>
		</main>
	</>
}

export default Layout;
