import * as React from "react";
import Voronoi from "./voronoi";

import style from "./style.module.css";

class Background extends React.Component {
	canvas: React.RefObject<HTMLCanvasElement>;
	canvasRenderer: CanvasRenderer;

	constructor(props: Readonly<{}>) {
		super(props);
		this.canvas = React.createRef();
		this.canvasRenderer = new Voronoi();
	}

	componentDidMount() {
		this.canvasRenderer.activate(this.canvas.current as HTMLCanvasElement);
	}

	componentWillUnmount() {
		this.canvasRenderer.deactivate();
	}

	render() {
		return <canvas ref={this.canvas} className={style.background} />
	}
}

export default Background;

export interface CanvasRenderer {
	activate(canvas: HTMLCanvasElement): void;
	deactivate(): void;
}
